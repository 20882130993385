/* eslint-disable no-unused-vars */
import Vue from "vue";
import Vuex from "vuex";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    isLoggedIn: false,
    sessionId: null,
    isSetGroupLogo: null,
    groupId: null,
  },
  getters: {
    isLoggedIn: (state) => state.isLoggedIn,
    sessionId: (state) => state.sessionId,
    isSetGroupLogo: (state) => state.isSetGroupLogo,
    groupId: (state) => state.groupId,
  },
  mutations: {
    auth_success(state, payload) {
      state.isLoggedIn = true;
      state.sessionId = payload.sessionid;
    },
    auth_error(state) {
      state.isLoggedIn = false;
      state.sessionId = null;
    },
    logout(state) {
      state.isLoggedIn = false;
      state.sessionId = null;
    },
    set_group_logo(state, payload) {
      state.isSetGroupLogo = payload;
    },
    set_group_id(state, payload) {
      state.groupId = payload;
    },
  },
  actions: {
    login({ commit }, payload) {
      console.log(payload);
      if (payload.status === "success") {
        localStorage.setItem("auth_success", JSON.stringify(payload));
        commit("auth_success", payload);
      } else {
        localStorage.setItem("auth_success", JSON.stringify({}));
        commit("auth_error");
      }
    },
    logout({ commit }, payload) {
      localStorage.removeItem("auth_success");
      commit("logout");
    },
    set_group_logo({ commit }, payload) {
      if (payload) {
        localStorage.setItem("set_group_logo", payload);
        commit("set_group_logo", payload);
      } else {
        localStorage.setItem("set_group_logo", "null");
        commit("set_group_logo", "null");
      }
    },
    set_group_id({ commit }, payload) {
      if (payload) {
        localStorage.setItem("set_group_id", payload);
        commit("set_group_id", payload);
      } else {
        localStorage.setItem("set_group_id", "null");
        commit("set_group_id", "null");
      }
    },
  },
  modules: {},
});
