<template>
  <div class="container text-start">
    <div class="d-flex mb-5">
      <h2 class="me-3">Your Groups</h2>
      <b-button variant="outline-primary" v-b-modal.modal-prevent-closing
        >Add Group</b-button
      >
    </div>
    <div class="row" v-if="groups.length > 0">
      <div
        v-for="val in groups"
        :key="val.id"
        class="col-12 col-sm-6 col-lg-3 col-md-4"
      >
        <div class="main-group-card">
          <div class="group-card">
            <div v-if="val.upgraded" class="group-card-upgraded">
              <font-awesome-icon :icon="['fas', 'star']" />
            </div>
            <div class="group-card-counts">
              <div class="d-flex justify-content-between">
                <span class="me-4">
                  <font-awesome-icon :icon="['fas', 'camera']" />
                </span>
                <span>{{ val.nrof_pictures }}</span>
              </div>

              <div class="d-flex justify-content-between my-2">
                <span class="me-4">
                  <font-awesome-icon :icon="['fas', 'list']" />
                </span>
                <span>{{ val.nrof_collections }}</span>
              </div>

              <div class="d-flex justify-content-between">
                <span class="me-4">
                  <font-awesome-icon :icon="['fas', 'users']" />
                </span>
                <span>{{ val.nrof_users }}</span>
              </div>

              <div class="d-flex justify-content-end mt-2">
                <a
                  v-if="val.isAdmin"
                  href="javascript:void(0)"
                  @click="openGroupDetailsModal(val.id, val)"
                >
                  <font-awesome-icon
                    :icon="['fas', 'edit']"
                    class="detail-edit-icon"
                  />
                </a>
              </div>
            </div>
            <div class="group-card-title">
              <b-link
                :to="`/group/${val.id}`"
                @click.native="setDataToStore(val.logo, val.id)"
                class="card-link"
              >
                {{ val.name }}
              </b-link>
            </div>
            <div class="group-card-img">
              <b-link
                :to="`/group/${val.id}`"
                @click.native="setDataToStore(val.logo, val.id)"
                class="card-link"
              >
              <img  class="align-middle"
                :src="
                  val.header_image
                    ? val.header_image
                    : 'http://placehold.jp/3d4070/ffffff/300x200.png?text=Cloudypics'
                "
              />
              </b-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <GroupDetailsModal
      :modal-show="isGroupDetailsModalVisible"
      :group_id="group_id"
      :group_data="group_data"
      @change-group-logo="updateGroupDetails"
      @close-group-details-modal="
        isGroupDetailsModalVisible = !isGroupDetailsModalVisible
      "
    />
    <b-modal
      id="modal-prevent-closing"
      ref="modal"
      title="Create Group"
      @show="resetModal"
      @hidden="resetModal"
      @ok="handleOk"
    >
      <form ref="form" @submit.stop.prevent="handleSubmit">
        <b-form-group
          label="Name"
          label-for="name-input"
          invalid-feedback="Name is required"
          :state="nameState"
        >
          <b-form-input
            id="name-input"
            v-model="name"
            :state="nameState"
            required
          ></b-form-input>
        </b-form-group>
      </form>
      <template #modal-footer="{ ok, cancel }">
        <b-button size="sm" variant="danger" @click="cancel()">
          Cancel
        </b-button>
        <b-button size="sm" variant="success" @click="ok()">
          Create Group</b-button
        >
      </template>
    </b-modal>
  </div>
</template>

<script>
import GroupDetailsModal from '@/components/GroupDetailsModal.vue'
export default {
  name: 'Home',
  components: {
    GroupDetailsModal,
  },
  data() {
    return {
      group_id: null,
      group_data: {},
      groups: [],
      isGroupDetailsModalVisible: false,
      name: '',
      nameState: null,
      submittedNames: [],
      csrf_token: null,
    }
  },
  methods: {
    setDataToStore(logo, groupId) {
      this.$store.dispatch('set_group_logo', logo)
      this.$store.dispatch('set_group_id', groupId)
    },
    checkFormValidity() {
      const valid = this.$refs.form.checkValidity()
      this.nameState = valid
      return valid
    },
    resetModal() {
      this.name = ''
      this.nameState = null
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      this.handleSubmit()
    },
    openGroupDetailsModal(group_id, group_data) {
      this.isGroupDetailsModalVisible = !this.isGroupDetailsModalVisible
      this.group_id = group_id
      this.group_data = group_data
    },
    handleSubmit() {
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return
      }
      this.add_group()
      this.$nextTick(() => {
        this.$bvModal.hide('modal-prevent-closing')
      })
    },
    add_group() {
      fetch(`${this.$app_base_url}/addGroup/`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'X-CSRFToken': this.$Csrf,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          name: this.name,
          private: true,
          csrfmiddlewaretoken: this.$Csrf,
        }),
      })
        .then((response) => response.json())
        .then(() => {
          this.$toast.success('Group created successfully')
          this.load_groups()
        })
    },
    load_groups() {
      var url = this.$app_base_url + '/getGroups/'
      fetch(url, {
        method: 'Get',
        credentials: 'include',
        headers: {
          'X-CSRFToken': this.$Csrf,
          'Content-Type': 'application/json',
        },
      })
        .then((response) => response.json())
        .then((data) => {
            console.log(data);
          this.groups = data['groups']
        })
    },
    updateGroupDetails() {
      this.load_groups()
    },
  },
  async mounted() {
    // console.log('document.cookie', this.$Csrf);
    this.load_groups()
  },
}
</script>

<style lang="scss">
.group-card {
  background: $light_grey;
  margin-bottom: 2em;
  padding: 1em;
  height: 20em;
  position:relative;
  
  display: flex;
  flex-flow: column;
}

.group-card-img {
  flex-grow: 1;
  min-height:0;
  margin:auto;
}

.group-card-title{
  flex-grow: 0;
}

.card-link{
    display:inline-block;
    height:100%;
    margin:0px;
}

.group-card-img img {
  max-height: 100%;
  max-width: 100%;
}

.group-card:hover .group-card-counts {
  display: inline-block;
}

.group-card-counts svg {
  font-size: 14px;
}

.group-card-counts {
  display: none;
  position: absolute;
  background: rgba($dark_grey, 0.5);
  top: 0;
  right: 0;
  font-size: 20px;
  color: $light_grey;
  padding: 10px;
  line-height: 20px;
  z-index: 10;
}

.group-card-title a {
  display: block;
  font-size: 20px;
  text-decoration: none;
  color: $secondary;
  margin: 20px 0;
}

.detail-edit-icon {
  color: $light_grey;
}

.group-card-upgraded {
  position: absolute;
  top: -15px;
  left: -10px;
  font-size: 20px;
  color: $primary;
}
</style>
