<template>
  <div class="container text-start">
    <p>You are now registered. Activation email sent.</p>
  </div>
</template>

<script>
export default {
  name: 'RegistrationComplete',
  components: {},
  data() {
    return {};
  },
  methods: {},
  mounted() {},
};
</script>
