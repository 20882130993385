<template>
  <div class="container text-start">
    <b-row align-h="center">
      <b-col cols="5">
        <b-card>
          <div>
            <h2 class="me-3">Please sign in</h2>
          </div>
          <b-form @submit.stop.prevent="onSubmit" class="form-group">
            <div class="input-field mb-4">
              <label for="username">Username</label>
              <input
                name="username"
                type="text"
                class="form-control"
                v-model="username"
                v-validate="'required'"
              />
              <span v-show="errors.has('username')" class="text-danger">{{
                errors.first('username')
              }}</span>
            </div>
            <div class="input-field">
              <label for="password">Password</label>
              <input
                name="password"
                type="password"
                class="form-control"
                v-model="password"
                v-validate="'required'"
              />

              <span v-show="errors.has('password')" class="text-danger">{{
                errors.first('password')
              }}</span>
            </div>
            <div class="center-align">
              <br />
              <b-button type="submit" variant="primary">Login</b-button>
              <hr />
              <p>
                Forgot password? -
                <router-link to="ResetPassword">Reset Now</router-link>!
              </p>
              <p>
                Don't have an account? -
                <router-link to="Register">Register Now</router-link>!
              </p>
            </div>
          </b-form>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
// import { validate } from "vee-validate";
export default {
  name: 'Home',
  components: {},
  data() {
    return {
      username: '',
      password: '',
    };
  },
  methods: {
    login(){
        console.log(document.cookie);
        console.log(this.$Csrf);
        console.log(this.$app_base_url);
        fetch(`${this.$app_base_url}/account/getsessionid/`, {
          method: 'POST',
          credentials: 'include',
          headers: {
            'X-CSRFToken': this.$Csrf,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            username: this.username,
            password: this.password,
            csrfmiddlewaretoken: this.$Csrf,
          }),
        })
      .then((response) => response.json())
      .then((data) => {
        console.log(data['sessionid']);
        this.$store.dispatch('login', data).then(() => {
          this.$router.push('/');
          this.$toast.info(data["message"])
        });
      })
      .catch(error =>{
         console.log("ERROR:", error);
         this.$toast.error("Failed to login, please refresh page and retry");
      });
    },
    onSubmit() {
      this.$validator.validateAll().then((result) => {
        if (!result) {
          return;
        }
        this.login();
      });
    },
  },
  mounted() {},
};
</script>
