<template>
  <div @click="$emit('read', notification)" class="d-flex cursor-pointer">
    <div class="py-2">
      <p class="mb-0">
        {{ notification.actor }} {{notification.verb }} 
        <span v-if="notification.target"> 
            to {{ notification.target }}
        </span>
        <span v-if="notification.description"> 
             {{ notification.description }}
        </span>
        <b-badge v-if="notification.unread" class="text-danger" variant="success">
            New
        </b-badge>
      </p>
      <p class="mb-0 text-muted fs-10">{{ notification.timestamp | formateDate }}</p>
    </div>
  </div>
</template>

<script>
import moment from "moment";
export default {
  props: {
    notification: {
      type: Object,
      required: true,
    },
  },
  components: {},
  data() {
    return {};
  },
  filters: {
    formateDate: function (value) {
      return moment(value).fromNow();
    },
  },
  computed: {},
  methods: {},
};
</script>
