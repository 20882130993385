<template>
  <nav class="navbar navbar-light mb-1">
    <div class="container">
      <b-link
        v-if="isSetGroupLogo != 'null' && ($route.name == 'GroupDetails' || $route.name == 'Collection')"
        to="/"
        class="navbar-brand"
      >
        <img :src="isSetGroupLogo" alt="Cloudypics" height="40" />
      </b-link>

      <b-link v-else to="/" class="navbar-brand">Cloudypics</b-link>
      <div class="d-flex" v-if="$route.params.groupId">
        <div class="input-group me-4" id="search">
          <input
            @keyup.enter="searchPicture()"
            v-model="searchQuery"
            type="text"
            name="search"
            placeholder="Search..."
            class="form-control"
          />
          <span class="input-group-text cursor-pointer" @click="searchPicture()"><i class="icon search-icon"></i></span>
          <AddCollection :query="this.searchQuery" />
        </div>

        <div class="d-flex">
          <button class="me-2 h-100 btn btn-outline-primary" 
                  @click="isModalVisible = !isModalVisible">
            Upload
          </button>
          <b-button v-if="lastSelectedImage" @click="$root.$emit('remove-all-selected')" variant="danger">
            Delete</b-button
          >
          <UploadModal
            :group-id="$route.params.groupId"
            :modal-show="isModalVisible"
            @close="isModalVisible = !isModalVisible"
          />
        </div>
      </div>

      <div v-if="isLoggedIn" class="d-flex align-items-center text-secondary">
        <div>
          <NotificationDropdown :notifications="notifications" />
        </div>

        <b-navbar-nav v-if="isLoggedIn" class="nav-right">
          <b-nav-item-dropdown right menu-class="profilemenu" no-caret>
            <div slot="button-content" class="">
              <div class="px-3">
                <b-avatar badge-top size="40">
                  <template #default>
                    <font-awesome-icon full-width :icon="['fas', 'user-circle']" class="text-secondary text-md" />
                  </template>
                </b-avatar>
              </div>
            </div>
            <ul class="list-group" style="overflow: hidden">
              <li class="px-2 py-1 border-bottom">My Account</li>
              <li class="px-2 py-1">
                <a href="javascript:void(0)" @click="logout()" class="text-black text-decoration-none"> Logout </a>
              </li>
            </ul>
          </b-nav-item-dropdown>
        </b-navbar-nav>
      </div>
      <div v-else>
        <b-link to="/account/register" v-if="!isLoggedIn" class="mb-0 text-white text-decoration-none me-3"
          >Register</b-link
        >
        <b-link to="/account/login" v-if="!isLoggedIn" class="mb-0 text-white text-decoration-none">Login</b-link>
      </div>
    </div>
  </nav>
</template>

<script>
import UploadModal from "./UploadModal.vue";
import AddCollection from "@/components/AddCollection.vue";
import NotificationDropdown from "@/components/notification/NotificationDropdown.vue";

export default {
  components: {
    UploadModal,
    NotificationDropdown,
    AddCollection,
  },
  data() {
    return {
      isModalVisible: false,
      lastSelectedImage: null,
      searchQuery: "",
      isProfileDropdown: false,
      notifications: [],
      notificationInterval: null,
    };
  },
  computed: {
    isLoggedIn: function () {
      return this.$store.getters.isLoggedIn;
    },
    isSetGroupLogo: function () {
      return this.$store.getters.isSetGroupLogo;
    },
  },
  methods: {
    logout: function () {
      this.$store.dispatch("logout").then(() => {
        this.$router.push("/account/login");
      });
    },
    searchPicture() {
      this.$root.$emit("search-picture", this.searchQuery);
    },
    getAllNotifications() {
      fetch(`${this.$app_base_url}/notifications/api/unread_list/`, {
        method: "Get",
        credentials: "include",
        headers: {
          "X-CSRFToken": this.$Csrf,
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((res) => {
          this.notifications = res.unread_list;
        });
    },
  },
  created() {
    this.notificationInterval = setInterval(() => {
      this.getAllNotifications();
    }, 30000);
    this.getAllNotifications();
    this.$root.$on("last-selected-image", (value) => {
      this.lastSelectedImage = value;
    });
    this.$root.$on("notification_read", () => {
      console.log("notification_read");
      this.getAllNotifications();
    });
  },
  beforeDestroy() {
    clearInterval(this.notificationInterval);
  },
};
</script>

<style lang="scss" scoped>
#search .search-icon {
  mask: url(../assets/icons/search.svg) no-repeat center;
  -webkit-mask: url(../assets/icons/search.svg) no-repeat center;
}
#search .add-collection-icon {
  mask: url(../assets/icons/add_collection.svg) no-repeat center;
  -webkit-mask: url(../assets/icons/add_collection.svg) no-repeat center;
}
#search .icon {
  display: inline-block;
  height: 2em;
  width: 2em;
  background-color: $light_blue;
}
#search .icon:hover {
  background-color: $dark_blue;
}

.profilemenu {
  width: 200px;
}
</style>
