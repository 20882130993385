
<template>
  <div class="container text-start">
    <b-row align-h="center">
      <b-col cols="5">
        <b-card>
          <div>
            <h2 class="me-3">Reset Password</h2>
          </div>
          <b-form @submit.stop.prevent="onSubmit" class="form-group">
            <div class="input-field mb-4">
              <label for="username">Email address</label>
              <input
                name="email"
                type="text"
                class="form-control"
                v-model="email"
                v-validate="'required'"
              />
            </div>

            <div class="center-align">
              <b-button type="submit" variant="primary">Reset Password</b-button>
            </div>
          </b-form>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
// import { validate } from "vee-validate";
export default {
  name: 'Home',
  components: {},
  data() {
    return {
      email: '',
    };
  },
  methods: {
    onSubmit() {
      this.$validator.validateAll().then((result) => {
        if (!result) {
          return;
        }
	console.log(this.$Csrf);
        fetch(`${this.$app_base_url}/account/resetPassword/`, {
          method: 'POST',
          credentials: 'include',
          headers: {
            'X-CSRFToken': this.$Csrf,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            email: this.email,
            csrfmiddlewaretoken: this.$Csrf,
          }),
        })
          .then((response) => response.json())
          .then((data) => {
console.log(data);
            this.$toast.info(data["message"])
            this.$router.push({name: 'Login'});
          });
      });
    },
  },
  mounted() {},
};
</script>
