<template>
  <div id="app">
    <Navbar />
    <router-view />
  </div>
</template>
<script>
// @ is an alias to /src
import Navbar from "@/components/Navbar.vue";

export default {
  name: "app",
  components: {
    Navbar,
  },
  created() {
    try {
      var grouplogo = localStorage.getItem("set_group_logo");
      this.$store.dispatch("set_group_logo", grouplogo);
      var groupId = localStorage.getItem("set_group_id");
      this.$store.dispatch("set_group_id", groupId);
    } catch (error) {
      this.$store.dispatch("set_group_logo", null);
      this.$store.dispatch("set_group_id", null);
    }
  },
};
</script>
<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
.cursor-pointer {
  cursor: pointer;
}
.fs-10 {
  font-size: 10px;
}
.fs-12 {
  font-size: 12px;
}
</style>
