<template>
  <div>
    <b-row v-for="(tag, key) in tags" :key="key">
      <b-col>
        <b-row class="my-2" v-if="tag.type === 'input'">
          <b-col sm="4">
            <div class="text-start">
              <label class="label" :for="`type-${tag.label}`">
                {{ tag.label }}<span v-if="tag.required">*</span>:
              </label>
            </div>
          </b-col>
          <b-col sm="8" class="text-start">
            <input
              :type="tag.inputType"
              v-model="tag.value"
              :name="tag.label"
              :value="tag.default"
              v-validate="tag.required ? 'required' : ''"
              class="form-control"
              @blur="emitEvent()"
            />
            <small v-if="tag.help" class="text-muted">{{ tag.help }}</small>
            <p v-show="errors.has(tag.label)" class="text-danger">
              {{ errors.first(tag.label) }}
            </p>
          </b-col>
        </b-row>

        <b-row class="my-2" v-if="tag.type === 'bool'">
          <b-col sm="12" class="text-start">
            <label :for="`type-${tag.label}`" class="pe-2">{{
              tag.label
            }}<span v-if="tag.required">*</span></label>
            <input
              v-model="tag.value"
              :type="tag.inputType"
              :name="tag.label"
              v-validate="tag.required ? 'required' : ''"
              :value="tag.default"
              @change="emitEvent()"
            />
            <small v-if="tag.help" class="text-muted">{{ tag.help }}</small>
            <p v-show="errors.has(tag.label)" class="text-danger">
              {{ errors.first(tag.label) }}
            </p>
          </b-col>
        </b-row>

        <b-row class="my-2" v-if="tag.type === 'choice'">
          <b-col sm="4" class="text-start">
            <label :for="`type-${tag.label}`">{{ tag.label }}<span v-if="tag.required">*</span>:</label>
          </b-col>
          <b-col sm="8" class="text-start">
            <select
              v-model="tag.value"
              :name="tag.label"
              v-validate="tag.required ? 'required' : ''"
              as="select"
              class="form-control"
              @change="emitEvent()"
            >
              <option value="">Please Choose...</option>
              <option
                :value="opt.value"
                v-for="opt in tag.options"
                :key="opt.value"
              >
                {{ opt.name }}
              </option>
            </select>
            <small v-if="tag.help" class="text-muted">{{ tag.help }}</small>
            <p v-show="errors.has(tag.label)" class="text-danger">
              {{ errors.first(tag.label) }}
            </p>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: 'tagTemplate',
  props: {
    tags: {
      type: Array,
      require: true,
    },
    validate: false,
  },

  data() {
    return {};
  },
  watch:{
    validate(newVal, OldVal){
        console.log("VALIDATE");
        if(newVal){
            this.emitEvent(false);
        }
    }
  },
  methods: {
    async emitEvent(silent=true) {
      let value = this.tags
        .map((x) => {
          let val = x.value ? x.value : null;
          
          return x.prefix ? x.prefix + ':' + val : val;
        })
        .join(',');

      this.$validator.validate("*", null, {silent:silent}).then((result) => {
        if (!result) {
          return;
        }
        this.$emit('tag-string', value);
      });
    },
  },
  created() {
    this.$root.$on('images-uploaded', () => {
      this.tags.forEach((tag) => {
        tag.value = null;
      });
    });
  },
};
</script>

<style scoped></style>
