<template>
  <span class="dropdown input-group-text">
    <span
      v-if="canAddCollection"
      class="cursor-pointer"
      id="dropdownMenuButton1"
      data-bs-toggle="dropdown"
      @click="handleCollectionClick()"
    >
      <i class="icon add-collection-icon"></i>
    </span>
    <ul
      v-if="showCollectionInput && canAddCollection"
      class="dropdown-menu add-collection-dropdown"
      style="min-width: 14rem"
    >
      <li class="dropdown-item-text">
        <input
          type="text"
          class="form-control"
          placeholder="Collection name..."
          v-model="collectionName"
          ref="nameInput"
        />

      </li>
      <li class="dropdown-item-text">
        <div class="form-check mt-2">
          <input
            class="form-check-input"
            type="checkbox"
            v-model="showtags"
            id="show_tags"
          />
          <label class="form-check-label" for="show_tags"> Show Tags </label>
        </div>
        <div class="form-check">
          <input
            class="form-check-input"
            type="checkbox"
            v-model="showauthor"
            id="show_author"
          />
          <label class="form-check-label" for="show_author">
            Show Author
          </label>
        </div>
        <div class="form-check">
          <input
            class="form-check-input"
            type="checkbox"
            v-model="showdate"
            id="show_date"
          />
          <label class="form-check-label" for="show_date"> Show Date </label>
        </div>
      </li>
      <li class="dropdown-item-text d-flex justify-content-between btn-group">
        <button
          type="button"
          class="btn btn-outline-primary d-flex justify-content-center"
          @click="addCollection"
        >
          Create Collection
        </button>
        <button
          type="button"
          class="btn btn-outline-secondary"
          @click="handleCollectionClick"
        >
          Cancel
        </button>
      </li>
    </ul>
  </span>
</template>
<script>
export default {
  name: 'AddCollection',

  props: {
    query: String,
  },

  data() {
    return {
      showCollectionInput: false,
      canAddCollection: false,
      collectionName: '',
      showtags:true,
      showauthor:false,
      showdate:false,
    }
  },
  computed: {
    groupId: function () {
      return this.$store.getters.groupId
    },
  },
  methods: {
    handleCollectionClick() {
      if (!this.showCollectionInput) {
        this.showCollectionInput = true
      } else {
        this.showCollectionInput = false
        this.collectionName = ''
      }
    },
    addCollection() {
      fetch(`${this.$app_base_url}/${this.groupId}/addCollection/`, {
        method: 'POST',
        credentials: 'include',
        body: JSON.stringify({
          query: this.query,
          name: this.collectionName,
          show_author: this.showauthor,
          show_tags: this.showtags,
          show_date: this.showdate,
          csrfmiddlewaretoken: this.$Csrf,
        }),
        headers: {
          'X-CSRFToken': this.$Csrf,
          'Content-Type': 'application/json',
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if(data["status"] === "success"){
              this.$toast.info(data["message"])
              this.showCollectionInput = false;
              this.collectionName = '';
              this.$root.$emit('collection-created')
          }else{
              this.$toast.error(data["message"])
          }
            
          this.$root.$emit('bv::toggle::collapse', 'collection-collapse')
        })
    },
    getCollectionCreateRights() {
      fetch(`${this.$app_base_url}/${this.groupId}/hasRights/`, {
        method: 'POST',
        credentials: 'include',
        body: JSON.stringify({
          rights_to_check: [['create', 'collections']],
        }),
        headers: {
          'X-CSRFToken': this.$Csrf,
          'Content-Type': 'application/json',
        },
      })
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
          this.canAddCollection = data['rights'][0]
        })
    },
  },
  created() {
    this.getCollectionCreateRights();
  },
  watch: {
    searchQuery: function (newVal) {
      if (newVal === '') {
        this.searchPicture()
      }
    },
  },
}
</script>

<style lang="scss" scoped>
#search .search-icon {
  mask: url(../assets/icons/search.svg) no-repeat center;
  -webkit-mask: url(../assets/icons/search.svg) no-repeat center;
}
#search .add-collection-icon {
  mask: url(../assets/icons/add_collection.svg) no-repeat center;
  -webkit-mask: url(../assets/icons/add_collection.svg) no-repeat center;
}
#search .icon {
  display: inline-block;
  width: 2em;
  height: 2em;
  background-color: $light_blue;
}
#search .icon:hover {
  background-color: $dark_blue;
}

.dropdown-menu {
  display: inline-block;
  postion:absolute;
  top:0;
  left:0;
}
</style>
