var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-start user-tooltip-icon"},[_c('span',{staticClass:"right d-flex"},[_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],class:[_vm.right_name, 'right-icon'],attrs:{"title":_vm.right_name}}),_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],class:[
        !_vm.canViewIcon ? `view-off-svg icon-active` : 'view icon',
        { off: !_vm.hasRight(1) },
      ],attrs:{"title":`View ${_vm.right_name}`},on:{"click":function($event){return _vm.toggleRight(1)}}}),_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],class:[
        !_vm.canEditIcon ? `edit-off-svg icon-active` : 'edit icon',
        { off: !_vm.hasRight(2) },
      ],attrs:{"title":`Edit or Create ${_vm.right_name}`},on:{"click":function($event){return _vm.toggleRight(2)}}}),_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],class:[
        !_vm.canDeleteIcon ? `delete-off-svg icon-active` : 'delete icon',
        { off: !_vm.hasRight(4) },
      ],attrs:{"title":`Delete ${_vm.right_name}`},on:{"click":function($event){return _vm.toggleRight(4)}}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }