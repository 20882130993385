<template>
  <div class="container text-start">
    <b-row align-h="center">
      <b-col cols="5">
        <b-card>
          <div>
            <h2 class="me-3">Register</h2>
          </div>
          <b-form @submit.stop.prevent="onSubmit" class="form-group">
            <div class="input-field mb-4">
              <label for="username">Username</label>
              <input
                name="username"
                type="text"
                class="form-control"
                v-model="username"
                v-validate="'required'"
              />
              <p>
                Required. 150 characters or fewer. Letters, digits and @/./+/-/_
                only.
              </p>
              <span v-show="errors.has('username')" class="text-danger">{{
                errors.first('username')
              }}</span>
              <span
                class="text-danger"
                v-if="apiErrors.hasOwnProperty('username')"
              >
                {{ apiErrors.username[0] }}
              </span>
            </div>
            <div class="input-field mb-4">
              <label for="username">Email address</label>
              <input
                name="email"
                type="text"
                class="form-control"
                v-model="email"
                v-validate="'required'"
              />
              <p>
                <span v-show="errors.has('email')" class="text-danger">{{
                  errors.first('email')
                }}</span>
              </p>
            </div>

            <div class="input-field mb-4">
              <label for="username">Password</label>
              <input
                name="password"
                type="Password"
                class="form-control"
                v-model="password1"
                v-validate="'required'"
                ref="confirm_password"
              />
              <p>
                <span v-show="errors.has('password')" class="text-danger">{{
                  errors.first('password')
                }}</span>
              </p>
            </div>

            <div class="input-field">
              <label for="password">Password confirmation</label>
              <input
                name="confirm_password"
                type="password"
                class="form-control"
                v-model="password2"
                v-validate="'required|confirmed:confirm_password'"
                data-vv-as="confirm_password"
              />
              <span
                v-show="errors.has('confirm_password')"
                class="text-danger"
                >{{ errors.first('confirm_password') }}</span
              >
            </div>
            <div class="center-align">
              <br />
              <b-button type="submit" variant="primary">Register</b-button>
            </div>
          </b-form>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: 'Home',
  components: {},
  data() {
    return {
      username: '',
      email: '',
      password1: '',
      password2: '',
      apiErrors: {},
    };
  },
  methods: {
    onSubmit() {
      this.$validator.validateAll().then((result) => {
        if (!result) {
          return;
        }

        var data = {
          username: this.username,
          email: this.email,
          password1: this.password1,
          password2: this.password2,
        };
        var formdata = new FormData();
        for (let key in data) {
          formdata.append(key, data[key]);
        }
        fetch(`${this.$app_base_url}/account/register/`, {
          method: 'POST',
          credentials: 'include',
          headers: {
            'X-CSRFToken': this.$Csrf,
            Accept: 'application/json',
          },
          body: formdata,
        })
          .then((response) => response.json())
          .then((data) => {
            if (data.status === 'success') {
              this.$toast.success('User registered successfully');
              this.$router.push('/account/register/complete');
            } else {
              this.$toast.error('User registration failed');
              this.apiErrors = data.errors;
            }
          })
          .catch((error) => {
            this.$toast.error("Error occured, contact the site administration.");
            this.apiErrors = error;
         });
      });
    },
  },
  mounted() {},
};
</script>
