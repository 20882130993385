<template>
  <div>
    <b-modal
      v-model="modalShow"
      ref="modal"
      @show="resetModal"
      @hidden="resetModal"
      modal-class="upload-modal"
    >
      <template #modal-header>
        <h4 class="mb-0">Upload images</h4>
        <a href="javascript:void(0)" @click="$emit('close')">
          <font-awesome-icon :icon="['fas', 'times']" />
        </a>
      </template>
      <form ref="form" @submit.stop.prevent="handleSubmit">
        <input
          type="file"
          multiple
          accept="image/*"
          @change="handleFilesAdded"
          :disabled="files.length > 0"
        />
        <ul class="thumbnails">
          <li
            class="thumbnail"
            v-for="(file, index) in files"
            :key="index"
            :class="file.status"
          >
            <span class="icon"></span>
            <span class="filename">{{ file.file.name }}</span>
          </li>
        </ul>
        <div v-if="tag_template.length > 0">
          <TagTemplate :tags="tag_template" @tag-string="getTagtemplateData" 
                       :validate="validate_tag_template"/>
        </div>
        <div v-else class="mt-3">
          <h5>Tags:</h5>
          <textarea class="w-100" v-model="tags"> </textarea>
        </div>
      </form>
      <template #modal-footer="{}">
        <b-button size="sm" variant="danger" @click="$emit('close')">
          Cancel
        </b-button>
        <b-button size="sm" variant="success" @click="uploadImages()"
            :disabled="files.length == 0"
            >
          Upload</b-button
        >
      </template>
    </b-modal>
  </div>
</template>

<script>
import TagTemplate from '@/components/TagTemplate.vue';
export default {
  props: {
    modalShow: {
      type: Boolean,
      default: false,
    },
    groupId: {
      type: String,
      require: true,
    },
  },
  components: {
    TagTemplate,
  },
  data() {
    return {
      tags: [],
      files: [],
      tag_template: [],
      validated:false,
      validate_tag_template:false,
    };
  },
  methods: {
    resetModal() {
      this.tags = [];
      this.files = [];
      this.validated = false;
      this.validate_tag_template=false;
    },

    handleFilesAdded(evt) {
      const files = evt.target.files;
      for (let i = 0; i < files.length; i++) {
        if (files[i].type.startsWith('image/')) {
          this.files.push({
            status: 'queued',
            done: false,
            file: files[i],
          });
        }
      }
    },
    getTagtemplateData(value) {
      console.log("TTD", value);
      this.tags = value;
      this.validated = true;
      this.validate_tag_templates=false;
    },
    uploadImage(file_to_upload, i) {
      const formdata = new FormData();
      formdata.append('tags', this.tags);
      formdata.append('csrfmiddlewaretoken', this.$Csrf);
      formdata.append('file', file_to_upload['file']);
      console.log(file_to_upload);
      this.$set(this.files[i], 'status', 'uploading');

      fetch(`${this.$app_base_url}/${this.groupId}/uploadPics/`, {
        method: 'POST',
        credentials: 'include',
        body: formdata,
        headers: {
          'X-CSRFToken': this.$Csrf,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
            var error_occured=false;
            for(var fname in data){ 
              this.$toast.error(fname + data[fname]);
              error_occured = true;
            }
          if(error_occured){
            this.$set(this.files[i], 'status', 'error');
          }else{
              this.$toast.success('Successfully uploaded!');
              this.$set(this.files[i], 'status', 'success');
          }
          this.$set(this.files[i], 'done', true);
          this.$root.$emit('call-load-picture-api');
        })
	.catch((e)=> {
          console.log(e);
	  this.$toast.error('Error Uploading the file');
          this.$set(this.files[i], 'status', 'error');
	});
    },
    async uploadImages() {
      console.log("V: ", this.validated);
      if(!this.validated && this.tag_template.length > 0){
        console.log("Validating");
        this.validate_tag_template = true;
        return; 
      }
      for (var i in this.files) {
        const file_to_upload = this.files[i];
        await this.uploadImage(file_to_upload, i);
      }
      this.$root.$emit('images-uploaded');
    },
    load_tag_template() {
      fetch(`${this.$app_base_url}/${this.groupId}/info/`, {
        method: 'GET',
        credentials: 'include',
        headers: {
          'X-CSRFToken': this.$Csrf,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.tag_template) {
            this.tag_template = JSON.parse(data.tag_template);
          }
        });
    },
  },
  mounted() {
    this.load_tag_template();
  },
};
</script>

<style lang="scss" scoped>
::v-deep .upload-modal .thumbnails {
  list-style: none;
}
::v-deep .upload-modal .thumbnails .success .icon {
  background-color: green;
  mask: url(../assets/icons/done.svg) no-repeat center;
  -webkit-mask: url(../assets/icons/done.svg) no-repeat center;
}
::v-deep .upload-modal .thumbnails .queued .icon {
  mask: url(../assets/icons/upload.svg) no-repeat center;
  -webkit-mask: url(../assets/icons/upload.svg) no-repeat center;
}
::v-deep .upload-modal .thumbnails .error .icon {
  background-color: red;
  mask: url(../assets/icons/error.svg) no-repeat center;
  -webkit-mask: url(../assets/icons/error.svg) no-repeat center;
}

::v-deep .upload-modal .thumbnails .uploading .icon {
  mask: url(../assets/icons/pending.svg) no-repeat center;
  -webkit-mask: url(../assets/icons/pending.svg) no-repeat center;
}

::v-deep .upload-modal .thumbnails .icon {
  background-color: $light_blue;
  display: inline-block;
  width: 24px;
  height: 24px;
}
::v-deep .upload-modal .thumbnails .filename {
  display: inline-block;
  width: 60%;
  overflow: hidden;
}
</style>
