import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '../store';
import Home from '../views/Home.vue';
import Login from '../views/Login.vue';
import ResetPassword from '../views/ResetPassword.vue';
import Registration from '../views/Registration.vue';
import RegistrationComplete from '../views/RegistrationComplete.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: { requiresLogin: true },
  },
  {
    path: '/collection/:collectionId',
    name: 'Collection',
    meta: { requiresLogin: true },
    component: () =>
      import(/* webpackChunkName: "Collection" */ '../views/Collection.vue'),
  },
  {
    path: '/group/:groupId',
    name: 'GroupDetails',
    meta: { requiresLogin: true },
    component: () =>
      import(
        /* webpackChunkName: "GroupDetails" */ '../views/GroupDetails.vue'
      ),
  },
  {
    path: '/account/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/account/register',
    name: 'Register',
    component: Registration,
  },
  {
    path: '/account/register/complete',
    name: 'RegistrationComplete',
    component: RegistrationComplete,
  },
  {
    path: '/account/resetpassword',
    name: 'ResetPassword',
    component: ResetPassword,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresLogin)) {
    // You can use store variable here to access globalError or commit mutation
    // console.log('beforeEach === ', store.getters.isLoggedIn);
    if (!store.getters.isLoggedIn) {
      try {
        const authData = JSON.parse(localStorage.getItem('auth_success'));
        if (Object.keys(authData).length > 0 && authData.status == 'success') {
          // console.log('if if === ', store.getters.isLoggedIn);
          store.dispatch('login', authData);
          next();
        } else {
          // console.log('if else  === ', store.getters.isLoggedIn);
          next('/account/login');
        }
      } catch (e) {
        next('/account/login');
        console.log('catch ', e);
      }
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
