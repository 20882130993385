import { render, staticRenderFns } from "./TagTemplate.vue?vue&type=template&id=585188bf&scoped=true&"
import script from "./TagTemplate.vue?vue&type=script&lang=js&"
export * from "./TagTemplate.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "585188bf",
  null
  
)

export default component.exports