<template>
  <div class="text-start user-tooltip-icon">
    <span class="right d-flex">
      <b-button
        v-b-tooltip.hover
        :title="right_name"
        :class="[right_name, 'right-icon']"
      />

      <b-button
        v-b-tooltip.hover
        :title="`View ${right_name}`"
        :class="[
          !canViewIcon ? `view-off-svg icon-active` : 'view icon',
          { off: !hasRight(1) },
        ]"
        @click="toggleRight(1)"
      />

      <b-button
        v-b-tooltip.hover
        :title="`Edit or Create ${right_name}`"
        :class="[
          !canEditIcon ? `edit-off-svg icon-active` : 'edit icon',
          { off: !hasRight(2) },
        ]"
        @click="toggleRight(2)"
      />

      <b-button
        v-b-tooltip.hover
        :title="`Delete ${right_name}`"
        :class="[
          !canDeleteIcon ? `delete-off-svg icon-active` : 'delete icon',
          { off: !hasRight(4) },
        ]"
        @click="toggleRight(4)"
      />
    </span>
  </div>
</template>

<script>
export default {
  name: 'GroupRight',
  props: {
    group_pk: Number,
    user_pk: Number,
    right_name: String,
    right_levels: Number, // The right level mask
  },

  components: {},

  data() {
    return {
      collections: [],
    }
  },

  computed: {
    canViewIcon() {
      return this.hasRight(1);
    },
    canEditIcon() {
      return this.hasRight(2);
    },
    canDeleteIcon() {
      return this.hasRight(4);
    },
  },

  methods: {
    toggleRight(level) {
      // Do a POST request to toggle the right
      var data = {
        right: this.right_name,
        level: level,
        csrfmiddlewaretoken: this.$Csrf,
      }
      data = JSON.stringify(data)

      var url = `${this.$app_base_url}/${this.group_pk}/toggleRight/` + this.user_pk +'/';
      fetch(url, {
        method: 'POST',
        credentials: 'include',
        body: data,
        headers: {
          'X-CSRFToken': this.$Csrf,
          'Content-Type': 'application/json',
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.$toast.info('User rights updated')
          data['user_pk'] = this.user_pk
          this.$emit('updated-rights', data)
        })
    },
    hasRight(level) {
      return (this.right_levels & level) > 0
    },
  },

  mounted() {},
  created() {},
}
</script>

<style lang="scss">
.user-tooltip-icon span.right button {
  margin-right: 6px;
}

.user-tooltip-icon span.right {
  // border: 1px solid #dadada;
  padding: 5px;
  border-radius: 4px;
  margin-right: 5px;
  background: $light_grey;
}

.group-detail .tooltip-inner {
  background: #555555;
  padding: 7px;
  line-height: 16px;
  font-size: 14px;
  top: -8px;
  position: relative;
  letter-spacing: 1px;
}

.group-detail .tooltip-inner::after {
  content: '';
  position: absolute;
  top: 99%;
  left: 50%;
  margin-left: -5px;
  border-width: 7px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.group-detail .bs-tooltip-top {
  padding: 0;
}

.group-detail .tooltip.b-tooltip {
  opacity: 1;
}

.group-detail button.btn.btn-secondary.view:hover.icon {
  background-color: $secondary;
}

.group-detail button.btn.btn-secondary.edit:hover.icon {
  background-color: $secondary;
}

.group-detail button.btn.btn-secondary.delete:hover.icon {
  background-color: $secondary;
}

.icon-active {
  height: 1.5em;
  width: 1.5em;
  cursor: pointer;
  background-color: $secondary;
}

.view-off-svg {
  mask: url('../assets/icons/view_off.svg') no-repeat center;
  -webkit-mask: url('../assets/icons/view_off.svg') no-repeat center;
}

.edit-off-svg {
  mask: url('../assets/icons/edit_off.svg') no-repeat center;
  -webkit-mask: url('../assets/icons/edit_off.svg') no-repeat center;
}

.delete-off-svg {
  mask: url('../assets/icons/delete_off.svg') no-repeat center;
  -webkit-mask: url('../assets/icons/delete_off.svg') no-repeat center;
}

.group-detail .view:hover .icon {
  background-color: $secondary;
}
</style>
