import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { BootstrapVue } from "bootstrap-vue";

// Import Bootstrap an BootstrapVue CSS files (order is important)
// import 'bootstrap/dist/css/bootstrap.css';
import "bootstrap-vue/dist/bootstrap-vue.css";

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue);

import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { fas } from "@fortawesome/free-solid-svg-icons";

library.add(fas);

Vue.component("font-awesome-icon", FontAwesomeIcon);

import VeeValidate from "vee-validate";

Vue.use(VeeValidate);

import { getCookie } from "../utility/help.js";
console.log("call main js file", Vue.config);
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
const options = {
  position: "top-right",
  timeout: 2446,
  newestOnTop: true,
  transition: "Vue-Toastification__fade",
  hideProgressBar: true,
};

Vue.use(Toast, options);
Vue.config.productionTip = false;

/**** 
function setTagTemplate(){
    const csrf_token = getCookie();
    fetch('/1/settagtemplate/', {
        method: 'POST',
        credentials:'include',
        body: JSON.stringify({
            'template': [{
                'name': "test",
                "type": "string"
            }]
        }),
        headers: {
          'X-CSRFToken': csrf_token,
          'Content-Type': 'application/json',
        },
      }).then(response => response.json()).then(data => console.log(data));
}
****/

function setCookie(name, value, days) {
  var expires = "";
  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "") + expires + "; path=/";
}

(async function () {
  const backend_url = process.env.VUE_APP_BACKEND_URL;
  const data = await fetch(backend_url + "/account/getcsrftoken/", {
    credentials: "include",
  }).then((r) => r.json());

  const token = data["csrftoken"]; // getCookie();
    if(!data["authenticated"]){
        localStorage.setItem('auth_success', false)
    }
  console.log("csrftoken token main js", token, data);
    console.log(backend_url);
  setCookie("csrftoken", token, 10);

  Vue.prototype.$Csrf = token;
  Vue.prototype.$app_base_url = backend_url;

  new Vue({
    router,
    store,
    render: (h) => h(App),
  }).$mount("#app");
})();
