<template>
  <div class="text-start">
    <b-modal
      v-model="modalShow"
      ref="modal"
      @show="resetModal"
      @hidden="resetModal"
      modal-class="group-detail"
    >
      <template #modal-header>
        <h4 class="mb-0">{{ group_data.name }}</h4>
        <a
          href="javascript:void(0)"
          @click="$emit('close-group-details-modal')"
        >
          <font-awesome-icon :icon="['fas', 'times']" />
        </a>
      </template>
      <div>
        <h5>Users ({{ users.length }})</h5>
        <ul class="list-group users">
          <li v-for="user in users" :key="user.pk" class="list-group-item">
            <span class="d-flex my-2">
              {{ user.username }}

              <button
                v-b-tooltip.hover
                title="Admin"
                class="icon admin ms-2"
                :class="{
                  off: !user.admin,
                  creator: user.creator,
                }"
                @click="toggle_admin(user.pk)"
              />

              <div class="ms-auto">
                <span>
                    <!-- {{ user.join_date }} -->
                </span>

                <button
                  class="btn btn-sm btn-outline-danger ms-auto"
                  @click="remove_user(user.pk)"
                  v-if="!user.admin"
                >
                  Remove
                </button>
              </div>
            </span>

            <div class="d-flex">
              <UserRight
                v-for="(rightname, i) in user.rightnames"
                :key="i"
                :right_name="rightname"
                :user_pk="user.pk"
                :group_pk="group_id"
                :right_levels="user.rights[rightname]"
                @updated-rights="updateRights"
              ></UserRight>
            </div>
          </li>
        </ul>
        <div class="my-2">
          <h5>Add User</h5>
          <div class="input-group">
            <input
              type="text"
              class="form-control"
              placeholder="Username or Email"
              v-model="username"
            />
            <button
              type="submit"
              class="btn btn-outline-primary"
              @click="add_user()"
            >
              Add User
            </button>
          </div>
          <div class="form-text">
            If the user does not exists we will create him an account
          </div>
        </div>

        <div class="my-2">
          <h5>Set Logo</h5>
          <img
            v-if="group_data.logo"
            :src="group_data.logo"
            class="mb-2 rounded"
            width="100"
            height="100"
          />
          <div class="input-group">
            <input class="form-control" type="file" @change="changelogo" />
            <button class="btn btn-outline-danger" @click="removelogo">
                Remove
            </button>
          </div>
        </div>
        <div class="my-2">
          <h5>Set Tag Template</h5>
          <div class="input-group">
            <textarea
              type="text"
              class="form-control"
              placeholder="Name..."
              v-model="tag_field"
            ></textarea>
            <button
              type="submit"
              class="btn btn-outline-primary"
              @click="setTagTemplate()"
            >
              Set Tag
            </button>
          </div>
        </div>
        <div class="my-2">
          <h5>Upgrade</h5>
          <div v-if="upgrades['active']">
          <h6>Active (until {{ (new Date(upgrades['active']['date_end'])).toLocaleDateString() }})</h6>
              <table class="upgrades">
                <tr><td>Max. Users</td><td>{{ upgrades['active']['nrof_users'] }}</td></tr>
                <tr><td>Max. Pictures</td><td>{{ upgrades['active']['nrof_pictures'] }}</td></tr>
                <!--
                <tr><td>GB Transfer</td><td>{{ upgrades['active']['nrof_gb_transfer'] }}</td></tr>
                <tr><td>GB Storage</td><td>{{ upgrades['active']['nrof_gb_storage'] }}</td></tr>
                <tr><td>Full Size Upload</td><td>{{ upgrades['active']['full_size_upload'] }}</td></tr>
                <tr><td>Create New User</td><td>{{ upgrades['active']['can_create_user'] }}</td></tr>
                -->
              </table>
          </div>
        </div>
      </div>
      <template #modal-footer="{}">
        <b-button
          size="sm"
          variant="secondary"
          @click="$emit('close-group-details-modal')"
        >
          close 
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import UserRight from '@/components/UserRight.vue'

var tooltipTriggerList = [].slice.call(
  document.querySelectorAll('[data-bs-toggle="tooltip"]')
)
var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
  return new bootstrap.Tooltip(tooltipTriggerEl)
})

export default {
  name: 'GroupDetailsModal',
  props: {
    modalShow: {
      type: Boolean,
      default: false,
    },
    group_id: Number,
    group_data: Object,
  },
  components: { UserRight },
  data() {
    return {
      users: [],
      collections: [],
      username: null,
      logofile: null,
      tag_template: [],
      tag_field: '',
      upgrades: {},
    }
  },

  methods: {
    resetModal() {},
    update_group() {
      this.load_users();
      this.load_upgrades();
      this.load_collections();
    },
    load_users() {
      var url = `${this.$app_base_url}/${this.group_id}/getUsers/`

      fetch(url, {
        method: 'GET',
        credentials: 'include',
        headers: {
          'X-CSRFToken': this.$Csrf,
          'Content-Type': 'application/json',
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.users = data['users']
        })
    },
    load_upgrades(){
      var url = `${this.$app_base_url}/${this.group_id}/getUpgrades/`

      fetch(url, {
        method: 'GET',
        credentials: 'include',
        headers: {
          'X-CSRFToken': this.$Csrf,
          'Content-Type': 'application/json',
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.upgrades = data;
        })
    },
    load_collections() {
      this.collection = []
    },
    updateRights(e) {
      const user = this.users.find((x) => x.pk == e['user_pk'])

      user.rights = e['rights']
    },
    add_user() {
      var data = {
        username: this.username,
        csrfmiddlewaretoken: this.$Csrf,
      }
      data = JSON.stringify(data)

      fetch(`${this.$app_base_url}/${this.group_id}/inviteToGroup/`, {
        method: 'POST',
        credentials: 'include',
        body: data,
        headers: {
          'X-CSRFToken': this.$Csrf,
          'Content-Type': 'application/json',
        },
      })
        .then((response) => response.json())
        .then((data) => {
	  console.log(data);
          if (data.status === 'success') {
            this.$toast.info(data.message);
          }else if(data.status === 'warning'){
            this.$toast.warn(data.message);
          }else if(data.status === 'warning'){
            this.$toast.error(data.message);
          }
        })
    },
    remove_user(user_pk) {
      var data = {
        csrfmiddlewaretoken: this.$Csrf,
      }
      data = JSON.stringify(data)

      fetch(`${this.$app_base_url}/${this.group_id}/removeUser/${user_pk}/`, {
        method: 'POST',
        credentials: 'include',
        body: data,
        headers: {
          'X-CSRFToken': this.$Csrf,
          'Content-Type': 'application/json',
        },
      })
        .then((response) => response.json())
        .then(() => {
          this.load_users()
        })
    },
    toggle_admin(user_pk) {
      var data = {
        csrfmiddlewaretoken: this.$Csrf,
      }
      data = JSON.stringify(data)

      fetch(`${this.$app_base_url}/${this.group_id}/toggleAdmin/${user_pk}/`, {
        method: 'POST',
        credentials: 'include',
        body: data,
        headers: {
          'X-CSRFToken': this.$Csrf,
          'Content-Type': 'application/json',
        },
      })
        .then((response) => response.json())
        .then((data) => {
          var user = this.users.find((x) => x.pk == user_pk)
          user.rights = data['rights']
          user.admin = data['admin']
        })
    },
    changelogo(evt) {
      const files = evt.target.files
      if (files.length == 0) {
        return // throw some error
      }
      const file = files[0]
      const formdata = new FormData()
      formdata.append('csrfmiddlewaretoken', this.$Csrf)
      formdata.append('logo', file)

      fetch(`${this.$app_base_url}/${this.group_id}/changeLogo/`, {
        method: 'POST',
        credentials: 'include',
        body: formdata,
        headers: {
          'X-CSRFToken': this.$Csrf,
        },
      })
        .then((response) => response.json())
        .then((res) => {
          if (res.status === 'success') {
            this.$toast.info(res.message)
          }
          this.$emit('change-group-logo')
        }).catch((error) => {
            this.$toast.error("Error: Logo not changed");
        })
    },
    removelogo() {
      const formdata = new FormData()
      formdata.append('csrfmiddlewaretoken', this.$Csrf)

      fetch(`${this.$app_base_url}/${this.group_id}/changeLogo/`, {
        method: 'POST',
        credentials: 'include',
        body: formdata,
        headers: {
          'X-CSRFToken': this.$Csrf,
        },
      })
        .then((response) => response.json())
        .then((res) => {
          if (res.status === 'success') {
            this.$toast.info(res.message)
          }

          this.$emit('change-group-logo')
        }).catch((error) => {
            this.$toast.error("Error: Logo not removed");
        })
            
    },
    setTagTemplate() {
      if (!confirm('Are you sure you want to replace the tag template?')) {
        return;
      }
      fetch(`${this.$app_base_url}/${this.group_id}/settagtemplate/`, {
        method: 'POST',
        credentials: 'include',
        body: JSON.stringify({
          template: this.tag_field,
        }),
        headers: {
          'X-CSRFToken': this.$Csrf,
          'Content-Type': 'application/json',
        },
      })
        .then((response) => response.json())
        .then(() => {
          this.$toast.info('Set tag template successfully')
        })
    },
  },
  mounted() {},
  watch: {
    group_id(newId) {
      if (newId === null) {
        return
      }
        console.log(this.group_data);
      this.tag_field = this.group_data["tagtemplate"];
      this.update_group();
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .group-detail .admin.creator {
  background-color: $primary;
  cursor: default;
}

::v-deep .group-detail .admin.creator:hover {
  background-color: $secondary;
  cursor: default;
}

::v-deep .group-detail .admin:hover {
  background-color: $secondary;
  cursor: default;
}

::v-deep .group-detail .icon {
  height: 1.5em;
  width: 1.5em;
  background-color: $primary;
  cursor: pointer;
}
::v-deep .group-detail .admin {
  mask: url(../assets/icons/admin.svg) no-repeat center;
  -webkit-mask: url(../assets/icons/admin.svg) no-repeat center;
}

::v-deep .group-detail .admin.off {
  mask: url(../assets/icons/admin_off.svg) no-repeat center;
  -webkit-mask: url(../assets/icons/admin_off.svg) no-repeat center;
  background-color: $secondary;
}


::v-deep .group-detail .right-icon {
  height: 1.5em;
  width: 1.5em;
  background-color: $secondary;
  flex-grow: 1.1;
}
::v-deep .group-detail .pictures {
  mask: url(../assets/icons/picture.svg) no-repeat center;
  -webkit-mask: url(../assets/icons/picture.svg) no-repeat center;
}
::v-deep .group-detail .view {
  mask: url(../assets/icons/view.svg) no-repeat center;
  -webkit-mask: url(../assets/icons/view.svg) no-repeat center;
}
::v-deep .group-detail .edit {
  mask: url(../assets/icons/edit.svg) no-repeat center;
  -webkit-mask: url(../assets/icons/edit.svg) no-repeat center;
}
::v-deep .group-detail .delete {
  mask: url(../assets/icons/delete.svg) no-repeat center;
  -webkit-mask: url(../assets/icons/delete.svg) no-repeat center;
}
::v-deep .group-detail .tags {
  mask: url(../assets/icons/add_tag.svg) no-repeat center;
  -webkit-mask: url(../assets/icons/add_tag.svg) no-repeat center;
}
::v-deep .group-detail .collections {
  margin-top: 0;
  mask: url(../assets/icons/collections.svg) no-repeat center;
  -webkit-mask: url(../assets/icons/collections.svg) no-repeat center;
}
.upgrades{
    width: 100%;
}

.users{
    max-height:30em;
    overflow-y:auto;
    overflow-x:hidden;
}

</style>
