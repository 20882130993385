<template>
  <div>
    <b-navbar-nav class="nav-right">
      <b-nav-item-dropdown right menu-class="notificationCenterMenu" no-caret>
        <div slot="button-content" class="">
          <div class="px-3">
            <b-avatar
              :badge="numbersOfNotifications > 0 ? numbersOfNotifications.toString() : null"
              badge-top
              size="40"
              variant="info"
            >
              <template #default
                ><font-awesome-icon full-width :icon="['fas', 'bell']" class="text-secondary text-md"
              /></template>
            </b-avatar>
          </div>
        </div>
        <div style="overflow: hidden">
          <div class="d-flex p-2 justify-content-between border-bottom">
            <div><b>Notifications</b></div>
            <div class="cursor-pointer text-secondary" @click.prevent="markAllRead()">Mark as read all</div>
          </div>
          <div class="pt-2 px-2" v-if="notifications.length > 0">
            <Notification v-for="val in notifications" :key="val.id" :notification="val" @read="markAsRead" />
          </div>
          <div class="pt-2 px-2 text-center" v-else>
            <p>Notification not found!</p>
          </div>
        </div>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
import Notification from "@/components/notification/Notification.vue";
export default {
  props: {
    notifications: {
      type: Array,
      required: true,
    },
  },
  components: {
    Notification,
  },
  data() {
    return {
      numbersOfNotifications: 0,
    };
  },
  computed: {
    countNotification: {
      get: function () {
        return this.notifications.length;
      },
      set: function (value) {
        console.log("dfdf", value);
        return value;
      },
    },
  },
  methods: {
    markAsRead(notification) {
      fetch(`${this.$app_base_url}/notifications/mark-as-read/${notification.slug}/`, {
        method: "Post",
        credentials: "include",
        headers: {
          "X-CSRFToken": this.$Csrf,
          "Content-Type": "application/json",
        },
      }).then((res) => {
        if (res.status === 200) {
          this.numbersOfNotifications -= 1;
          this.$root.$emit("notification_read", true);
        }
      });
    },
    markAllRead() {
      fetch(`${this.$app_base_url}/notifications/mark-all-as-read/`, {
        method: "Post",
        credentials: "include",
        headers: {
          "X-CSRFToken": this.$Csrf,
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((res) => {
          if (res.status === 200) {
            this.numbersOfNotifications = 0;
            this.$root.$emit("notification_read", true);
          }
        });
    },
  },
  mounted() {
    this.numbersOfNotifications = this.notifications.length;
  },
  watch: {
    notifications: function (newVal, oldVal) {
      this.notifications = newVal;
      this.numbersOfNotifications = this.notifications.length;
    },
  },
};
</script>
<style>
.b-avatar-badge {
  color: white;
  background: red;
}
.text-md {
  font-size: 1.7rem;
}

/* width */
.notificationCenterMenu::-webkit-scrollbar {
  width: 8px;
}

/* Track */
.notificationCenterMenu::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.notificationCenterMenu::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 20px;
}

/* Handle on hover */
.notificationCenterMenu::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.notificationCenterMenu {
  right: -690% !important;
  left: auto !important;
  width: 400px !important;
  max-height: 500px !important;
  border-radius: 0px 0px 3px 3px;
  margin: 0px;
  border: 0px;
  color: #fff;
  overflow: hidden;
  max-height: 500px !important;
  scrollbar-gutter: stable;
}
.notificationCenterMenu:hover {
  overflow-y: auto;
  max-height: 500px !important;
}
</style>
